<template>
  <div class="table">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i>
          类型配置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
          type="success"
          icon="el-icon-plus"
          size="small"
          @click="onAdd(data)"
        >
          新建
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          class="handle-del mr10"
          @click="delAll"
        >
          删除
        </el-button>
        <el-button icon="el-icon-refresh" class="mr10" @click="onRefresh">
          刷新
        </el-button>
      </div>
      <div class="custom-tree-container">
        <div class="block">
          <el-tree
            ref="tree"
            empty-text="暂无数据信息"
            highlight-current
            :data="data"
            show-checkbox
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="{ label: 'name', children: 'childrensList' }"
          >
            <span slot-scope="{ node, item }" class="custom-tree-node">
              <span>{{ node.label }}</span>
              <span>
                <el-button
                  type="text"
                  class="success"
                  size="small"
                  @click="onAdd(item)"
                >
                  添加
                </el-button>
                <el-button
                  type="text"
                  class="primary"
                  size="small"
                  @click="onEdit(item)"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  class="danger"
                  size="small"
                  @click="onDel(item)"
                >
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
    </div>

    <!--弹出对话框-->
    <el-dialog
      v-loading="loading"
      title="编辑类型"
      :visible.sync="dialogFormVisible"
      width="50%"
      :destroy-on-close="true"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="120px"
        class="demo-ruleForm"
        destroy-on-close
      >
        <el-form-item label="上级类型">
          <el-cascader
            v-model="form.pid"
            :options="typeList.options"
            :show-all-levels="false"
            :props="{
              value: 'id',
              label: 'name',
              children: 'childrensList',
              emitPath: false,
              checkStrictly: true
            }"
            placeholder="请选择备装类型"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="类型名称" prop="name" required>
          <el-input
            v-model="form.name"
            placeholder="请输入类型名称"
            style="width:240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传相关配图">
          <el-upload
            ref="upload"
            action=""
            list-type="picture-card"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :file-list="fileImageList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="图片" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  saveType,
  getAllTypeList,
  getTypeInfo,
  delType,
  uploadPicType,
  delUploadImg
} from '@/api/equipment';
import { Message, MessageBox } from 'element-ui';
import { constants } from 'fs';
let id = 1000;

export default {
  name: 'EquipmentType',
  data() {
    const data = [];
    return {
      data: JSON.parse(JSON.stringify(data)),
      dialogFormVisible: false,
      loading: false,
      form: {
        id: '',
        pid: '',
        name: '',
        imageList: []
      },
      fileImageList: [],
      typeList: { options: [] },
      multipleSelection: [],
      dialogImageUrl: '',
      dialogVisible: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handleRemove(file, fileList) {
      var id = file.id;
      delUploadImg(file.id).then(response => {
        if (response.code == 200) {
          this.$message('删除成功');
        } else {
          this.$message.error('删除失败');
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload(param) {
      if (this.form.name == '' || this.form.name == null) {
        this.$message.error('请先输入类型名称');
        return false;
      }
    },
    uploadFile(param) {
      let uploadData = new FormData();
      uploadData.append('file', param.file);
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      uploadPicType(uploadData, config).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '上传成功',
            type: 'success'
          });
          var id = resp.data.id;
          this.form.imageList.push(id);
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    onRefresh() {
      this.fetchData();
    },
    save() {
      this.loading = true;
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          saveType(this.form)
            .then(resp => {
              if (resp.code == 200) {
                this.$message({
                  message: resp.msg,
                  type: 'success'
                });
                this.dialogFormVisible = false;
                this.loading = false;
                this.fetchData();
              } else {
                this.$message.error(resp.msg);
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    onAdd(data) {
      this.fileImageList = [];
      this.typeList.options = [];
      getAllTypeList().then(response => {
        this.typeList.options = response.data;
      });
      this.form = {
        pid: data.id,
        id: '',
        name: '',
        imageList: []
      };
      this.dialogFormVisible = true;
    },
    onEdit(data) {
      this.fileImageList = [];
      this.typeList.options = [];
      getAllTypeList().then(response => {
        this.typeList.options = response.data;
      });

      if (data.imageList != null && data.imageList.length > 0) {
        this.fileImageList = data.imageList;
      }
      this.form = {
        id: data.id,
        pid: data.pid,
        name: data.name,
        imageList: []
      };
      this.dialogFormVisible = true;
    },
    delAll() {
      this.multipleSelection = this.$refs.tree.getCheckedNodes();
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delType({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    onDel(data) {
      this.$confirm('此操作将永久删除尺寸配置数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delType({ id: data.id })
          .then(resp => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    fetchData() {
      //查询列表
      this.listLoading = true;
      getAllTypeList().then(response => {
        this.data = response.data;
      });
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 5px;
}
.custom-tree-node {
  margin: 10px 10px;
}
.el-button--text {
  margin-left: 10px;
}
.custom-tree-node {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.success {
  color: #67c23a;
}
.primary {
  color: #409eff;
}
.danger {
  color: #f56c6c;
}
</style>
