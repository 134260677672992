var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"crumbs"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_c('i',{staticClass:"el-icon-lx-cascades"}),_vm._v(" 类型配置 ")])],1)],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"handle-box"},[_c('el-button',{attrs:{"type":"success","icon":"el-icon-plus","size":"small"},on:{"click":function($event){return _vm.onAdd(_vm.data)}}},[_vm._v(" 新建 ")]),_c('el-button',{staticClass:"handle-del mr10",attrs:{"type":"danger","icon":"el-icon-delete"},on:{"click":_vm.delAll}},[_vm._v(" 删除 ")]),_c('el-button',{staticClass:"mr10",attrs:{"icon":"el-icon-refresh"},on:{"click":_vm.onRefresh}},[_vm._v(" 刷新 ")])],1),_c('div',{staticClass:"custom-tree-container"},[_c('div',{staticClass:"block"},[_c('el-tree',{ref:"tree",attrs:{"empty-text":"暂无数据信息","highlight-current":"","data":_vm.data,"show-checkbox":"","node-key":"id","default-expand-all":"","expand-on-click-node":false,"props":{ label: 'name', children: 'childrensList' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var item = ref.item;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',[_vm._v(_vm._s(node.label))]),_c('span',[_c('el-button',{staticClass:"success",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.onAdd(item)}}},[_vm._v(" 添加 ")]),_c('el-button',{staticClass:"primary",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v(" 编辑 ")]),_c('el-button',{staticClass:"danger",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.onDel(item)}}},[_vm._v(" 删除 ")])],1)])}}])})],1)])]),_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":"编辑类型","visible":_vm.dialogFormVisible,"width":"50%","destroy-on-close":true},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.form,"label-width":"120px","destroy-on-close":""}},[_c('el-form-item',{attrs:{"label":"上级类型"}},[_c('el-cascader',{attrs:{"options":_vm.typeList.options,"show-all-levels":false,"props":{
            value: 'id',
            label: 'name',
            children: 'childrensList',
            emitPath: false,
            checkStrictly: true
          },"placeholder":"请选择备装类型"},model:{value:(_vm.form.pid),callback:function ($$v) {_vm.$set(_vm.form, "pid", $$v)},expression:"form.pid"}})],1),_c('el-form-item',{attrs:{"label":"类型名称","prop":"name","required":""}},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入类型名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"上传相关配图"}},[_c('el-upload',{ref:"upload",attrs:{"action":"","list-type":"picture-card","http-request":_vm.uploadFile,"before-upload":_vm.beforeUpload,"on-preview":_vm.handlePictureCardPreview,"on-remove":_vm.handleRemove,"file-list":_vm.fileImageList}},[_c('i',{staticClass:"el-icon-plus"})]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":"图片"}})])],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }