import request from '../utils/request';

//保存尺寸信息
export function saveSize(data) {
  return request({
    url: '/equipment/model/saveSize',
    method: 'post',
    data
  });
}

//获取尺寸列表
export function getSizeList(data) {
  return request({
    url: '/equipment/model/getSizeList',
    method: 'post',
    data
  });
}

//获取尺寸详细信息
export function getSizeInfo(data) {
  return request({
    url: '/equipment/model/getSizeInfo',
    method: 'post',
    data
  });
}

//删除尺寸配置
export function delSize(data) {
  return request({
    url: '/equipment/model/delSize',
    method: 'post',
    data
  });
}

//保存备装类型
export function saveType(data) {
  return request({
    url: '/equipment/model/saveType',
    method: 'post',
    data
  });
}

//获取所有的备装列表
export function getAllTypeList(data) {
  return request({
    url: '/equipment/model/getAllTypeList',
    method: 'post',
    data
  });
}

//获取备装类型详细信息
export function getTypeInfo(data) {
  return request({
    url: '/equipment/model/getTypeInfo',
    method: 'post',
    data
  });
}

//删除类型
export function delType(data) {
  return request({
    url: '/equipment/model/delType',
    method: 'post',
    data
  });
}

//删除交换信息
export function delExchange(data) {
  return request({
    url: '/equipment/exchange/del',
    method: 'post',
    data
  });
}

//查询备装互换列表
export function getExchangeList(data) {
  return request({
    url: '/equipment/exchange/getEquipmentInfoList',
    method: 'post',
    data
  });
}

//管理员核销
export function updateStatus(data) {
  return request({
    url: '/equipment/exchange/doComplete',
    method: 'post',
    data
  });
}

export function uploadPicType(data, config) {
  return request({
    url: '/equipment/model/uploadType',
    method: 'post',
    config,
    data
  });
}

/**
 * 删除上传的图片
 * @param {Object} id
 */
export function delUploadImg(id) {
  return request({
    url: '/equipment/model/delUploadImg',
    method: 'post',
    params: { id: id }
  });
}

/**
 * 查看申请交换的物品列表
 * @param {Object} id
 */
export function getApplyExchangeList(data) {
  return request({
    url: '/equipment/exchange/getApplyExchangeList',
    method: 'post',
    data
  });
}

/**
 * 【类型】查询一级类型
 * */
export function getParentTypeList(data) {
  return request({
    url: '/equipment/model/getParentTypeList',
    method: 'post',
    data
  });
}
